body {
  margin: 0;
  font-family: "Montserrat";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

button {
  cursor: pointer !important;
}

button:hover {
  opacity: 0.8 !important;
}

button:active {
  opacity: 0.6 !important;
}

.white-border .MuiOutlinedInput-root {
  color: white !important;
}

.white-border .MuiOutlinedInput-notchedOutline {
  border-color: white !important;
}

.dark-border .MuiOutlinedInput-root {
  color: #160f31 !important;
}

.dark-border .MuiOutlinedInput-notchedOutline {
  border-color: #160f31 !important;
}

/* GRADIENTS */

.gradient-border-button {
  position: relative !important;
  padding: 10px 40px !important;
  border: none;
  outline: none;
  position: relative;
  z-index: 1;
  border-radius: 55px !important;
  background-image: linear-gradient(to right, #fc6964, #fcc442);
}

.gradient-border-button::before {
  content: "";
  position: absolute;
  left: 1px;
  right: 1px;
  top: 1px;
  bottom: 1px;
  border-radius: 54px;
  background-color: #160f31;
  z-index: -1;
  transition: 200ms;
}

.btn-outline-gradient {
  position: relative !important;
  padding: 10px 40px !important;
  border: none;
  outline: none;
  position: relative;
  color: #160f31 !important;
  z-index: 1;
  border-radius: 55px !important;
  background-image: linear-gradient(to right, #fc6964, #fcc442);
}

.btn-outline-gradient_token {
  background: linear-gradient(to right, #450d5f, #700c9f);
}

.btn-outline-gradient_collateral {
  background-image: linear-gradient(to right, #695128, #160f31);
}

.btn-outline-gradient::before {
  content: "";
  position: absolute;
  left: 1px;
  right: 1px;
  top: 1px;
  background-color: white;
  bottom: 1px;
  border-radius: 54px;
  z-index: -1;
  transition: 200ms;
}

.text-gradient {
  background: linear-gradient(to right, #fc6964, #fcc442);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.btn-gradient {
  padding: 10px 40px !important;
  border-radius: 55px !important;
  background: linear-gradient(to right, #fc6964, #fcc442);
  color: #ffffff !important;
}

.btn-gradient_token {
  padding: 10px 40px !important;
  border-radius: 55px !important;
  background: linear-gradient(to right, #450d5f, #700c9f);
  color: #ffffff !important;
}

.btn-gradient_collateral {
  padding: 10px 40px !important;
  border-radius: 55px !important;
  background: linear-gradient(to right, #695128, #160f31);
  color: #ffffff !important;
}

.btn-gradient:disabled,
.btn-gradient_token:disabled,
.btn-gradient_collateral:disabled {
  opacity: 0.2;
}

/* DIALOG DARK */

.dk-dialog-dark #dk-dialog-title,
.dk-dialog-dark #dk-dialog-content,
.dk-dialog-dark #dk-dialog-content #dk-dialog-description,
.dk-dialog-dark #dk-dialog-actions {
  background-color: #160f31;
  color: white;
  text-align: center;
}

.dk-dialog #dk-dialog-title,
.dk-dialog #dk-dialog-content,
.dk-dialog #dk-dialog-content #dk-dialog-description,
.dk-dialog #dk-dialog-actions {
  background-color: white;
  color: #160f31;
  text-align: center;
}

#dk-dialog-description {
  display: flex;
  align-items: center;
}

#dk-dialog-actions {
  display: flex;
  justify-content: center;
  align-content: center;
}

#dk-dialog-title {
  text-transform: uppercase;
}

/* TRANSITIONS */
.formTransition-enter {
  opacity: 0;
  transform: scaleY(0.9);
}

.formTransition-enter-active {
  opacity: 1;
  transform: scaleY(1);
  transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
}

.formTransition-exit {
  opacity: 1;
  transform: scaleY(1);
}

.formTransition-exit-active {
  opacity: 0;
  transform: scaleY(0.9);
  transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
}

.layout-container {
  margin: 92px 0rem 0rem 0rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  > * {
    width: 100%;
  }
}

#bodegas .slick-track {
  display: flex !important;
  align-items: center !important;
  gap: 2rem !important;
}

#bodegas .slick-slide {
  cursor: pointer;
}

#bodegas .slick-slide:hover {
  opacity: 0.8;
}

#bodegas .slick-slide:active {
  opacity: 0.6;
}

#bodegas img {
  width: 80% !important;
}

#marketplace-benefits .swiper-wrapper {
  max-width: 1024px;
}

#marketplace-benefits .swiper-button-prev {
  transform: rotate(0deg) scale(1) !important;
  left: calc(50% - 540px) !important;
}

#marketplace-benefits .swiper-button-next {
  transform: rotate(180deg) scale(1) !important;
  right: calc(50% - 540px) !important;
}

#marketplace-benefits .swiper-button-next::after {
  display: none;
}

#marketplace-benefits .swiper-button-prev::after {
  display: none;
}

#marketplace-benefits .swiper-button-prev,
#marketplace-benefits .swiper-button-next {
  top: 74px !important;
}

#marketplace-benefits .swiper-pagination-clickable .swiper-pagination-bullet {
  background-color: #f36e1e !important;
}

#marketplace-benefits .swiper-button-prev,
#marketplace-benefits .swiper-button-next {
  color: #f36e1e !important;
  z-index: 5 !important;
}
#marketplace-news .swiper-wrapper {
  max-width: 1024px;
}

#marketplace-news .swiper-button-prev {
  transform: rotate(0deg) scale(1) !important;
  left: calc(48% - 540px) !important;
}

#marketplace-news .swiper-button-next {
  transform: rotate(180deg) scale(1) !important;
  right: calc(48% - 540px) !important;
}

#marketplace-news .swiper-button-next::after {
  display: none;
}

#marketplace-news .swiper-button-prev::after {
  display: none;
}

#marketplace-news .swiper-button-prev,
#marketplace-news .swiper-button-next {
  top: 155px !important;
}
#marketplace-news .swiper-button-prev {
  top: 155px !important;
}

#marketplace-news .swiper-pagination-clickable .swiper-pagination-bullet {
  background-color: #f36e1e !important;
}

#marketplace-news .swiper-button-prev,
#marketplace-news .swiper-button-next {
  color: #f36e1e !important;
  z-index: 5 !important;
}

.google-login {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  height: 3rem;
  > * {
    width: 100%;
    > div {
      > div {
        height: 3rem;
        padding: 0.5rem 1rem;
        border-radius: 55px !important;
        span {
        }
      }
    }
  }
}

.swiper-product-top {
  margin: 1rem;
  width: 100%;
  max-width: 480px;
  height: 100%;
  max-height: 480px;
}

.swiper-product-top .swiper-wrapper {
  align-items: center;
}

.swiper-product-top .swiper-button-next,
.swiper-product-top .swiper-button-prev {
  color: #f36e1e !important;
}

.swiper-product-bottom {
  width: 100%;
  max-width: 360px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-product-top .swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.swiper-product-bottom {
  width: 100%;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.swiper-product-bottom .swiper-wrapper {
  width: 100%;
  display: flex !important;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.swiper-product-bottom .swiper-slide {
  margin: 0rem !important;
  max-width: 82.5px;
  max-height: 82.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  opacity: 0.4;
  overflow: hidden;
}

.swiper-product-top .swiper-slide img,
.swiper-product-bottom .swiper-slide img,
.swiper-product-top .swiper-slide video,
.swiper-product-bottom .swiper-slide video {
  margin: 0rem !important;
  width: 100%;
  height: 100%;
}

.swiper-product-top .swiper-slide img,
.swiper-product-top .swiper-slide video {
  max-width: 480px;
  max-height: 480px;
}

.swiper-product-top .swiper-slide img,
.swiper-product-bottom .swiper-slide img {
  object-fit: contain;
}

.swiper-product-top .swiper-slide video {
  object-fit: contain;
  max-height: 480px;
}

.swiper-product-bottom .swiper-slide video,
.swiper-product-bottom .swiper-slide img {
  max-width: 82.5px;
  max-height: 82.5px;
}

.swiper-product-bottom .swiper-slide img,
.swiper-product-bottom .swiper-slide video {
  cursor: pointer;
}

.swiper-product-bottom .swiper-slide-thumb-active {
  opacity: 1;
}

.pagination {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.page-link-blue {
  color: black !important;
  cursor: pointer !important;
}

.page-link.active {
  background-color: lightyellow !important;
  cursor: default !important;
}

.active > .page-link,
.page-link.active {
  border-color: black !important;
}

@media (max-width: 1024px) {
  .layout-container {
    margin: 56px 0rem 0rem 0rem;
  }

  #marketplace-benefits .swiper-button-prev,
  #marketplace-benefits .swiper-button-next {
    display: none !important;
  }
  /* #marketplace-news .swiper-button-prev, #marketplace-news .swiper-button-next {
    display: none !important;
  } */
}

@media (max-width: 600px) {
  #marketplace-benefits .swiper {
    display: none !important;
  }
}

/* BTN GOOGLE */

.gsi-material-button {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-appearance: none;
  background-color: WHITE;
  background-image: none;
  border: 1px solid #747775;
  -webkit-border-radius: 20px;
  border-radius: 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #1f1f1f;
  cursor: pointer;
  font-family: "Roboto", arial, sans-serif;
  font-size: 14px;
  height: 40px;
  letter-spacing: 0.25px;
  outline: none;
  overflow: hidden;
  padding: 0 12px;
  position: relative;
  text-align: center;
  -webkit-transition: background-color 0.218s, border-color 0.218s,
    box-shadow 0.218s;
  transition: background-color 0.218s, border-color 0.218s, box-shadow 0.218s;
  vertical-align: middle;
  white-space: nowrap;
  width: auto;
  max-width: 400px;
  min-width: min-content;
  padding: 10px 40px !important;
}

.gsi-material-button .gsi-material-button-icon {
  height: 20px;
  margin-right: 12px;
  min-width: 20px;
  width: 20px;
}

.gsi-material-button .gsi-material-button-content-wrapper {
  -webkit-align-items: center;
  align-items: center;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  height: 100%;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.gsi-material-button .gsi-material-button-contents {
  -webkit-flex-grow: 1;
  flex-grow: 1;
  font-family: "Roboto", arial, sans-serif;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
}

.gsi-material-button .gsi-material-button-state {
  -webkit-transition: opacity 0.218s;
  transition: opacity 0.218s;
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.gsi-material-button:disabled {
  cursor: default;
  background-color: #ffffff61;
  border-color: #1f1f1f1f;
}

.gsi-material-button:disabled .gsi-material-button-contents {
  opacity: 38%;
}

.gsi-material-button:disabled .gsi-material-button-icon {
  opacity: 38%;
}

.gsi-material-button:not(:disabled):active .gsi-material-button-state,
.gsi-material-button:not(:disabled):focus .gsi-material-button-state {
  background-color: #303030;
  opacity: 12%;
}

.gsi-material-button:not(:disabled):hover {
  -webkit-box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
}

.gsi-material-button:not(:disabled):hover .gsi-material-button-state {
  background-color: #303030;
  opacity: 8%;
}

.chatWhats {
  position: fixed;
  width: 2.725rem;
  height: 2.6rem;
  padding: 0.125rem;
  bottom: 1rem;
  right: 2rem;
  z-index: 999999;
  background-color: black;
  border-radius: 55%;
  cursor: pointer;
}

.chatWhats svg {
  width: 2.7rem;
  height: 2.7rem;
  color: white;
}
