.stats-container {
  width: 100%;
  margin: auto;
  padding: 20px 0;
  font-family: Arial, sans-serif;
  color: #fff;
  text-align: center;

  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }

  table,
  th,
  td {
    border: 1px solid rgb(255, 255, 255);
    padding: 10px;
    text-align: left;
  }

  /*  th {
    background-color: #fff;
  }

  td {
    background-color: #fff;
  } */
}

.stats-bold {
  font-weight: bold;
}
